<template>
    <div id="centerRight2">
      <div class="bg-color-black">
        <div class="d-fle title11">
          <span class="fs-xl text_title commonTitle" @click="go()">织物库存物排名</span>
        </div>
        <div class="d-flex flex-column body-box title22">
          <Chart v-if="showChart" :listData="listData" type="stock" />
        </div>
      </div>
    </div>
</template>

<script>
import Chart from './chart.vue'
import * as api from '@/api/index'
import utils from '@/utils'
import { sortArrayByKey } from '../../utils';
export default {
  data() {
    return {
      showChart:false,
      listData: [
        // {
        //   customerName: "xxxxxxx医院",
        //   num: "100",
        //   rate:'30',
        // },
        // {
        //   customerName: "xxxxxxx医院",
        //   num: "200",
        //   rate:'50',
        // }, {
        //   customerName: "xxxxxxx医院",
        //   num: "300",
        //   rate:'20',
        // }, 
        // {
        //   customerName: "xxxxxxx医院",
        //   num: 400,
        // },
        // {
        //   customerName: "xxxxxxx医院",
        //   num: "200",
        // }, {
        //   customerName: "xxxxxxx医院",
        //   num: "300",
        // }, {
        //   customerName: "xxxxxxx医院",
        //   num: 400,
        // },
      ],
      params: {
        pageNum: 1,
        pageSize: 10,
        startTime: '',
        endTime: '',
        customerId: ''
      },
      result: []
    }
  },
  components: {
    Chart
  },
  mounted() {
    const info = JSON.parse(localStorage.getItem('userInfo'))
    this.params.customerId = this.$route.query.customerId

    this.getBigSkuCustomer()
  },
  methods: {
    async getBigSkuCustomer(){
      const res = await api.getBigSkuCustomer({})
      console.error(res.Result)
      this.$nextTick(() => {
        this.showChart = true
        this.listData = res.Result.map(item =>{
          item.showNum = item.num
          return item
        })
        this.listData = sortArrayByKey(this.listData,'showNum')
        console.log(res.Result,'res.Result')
      })
    },
    go(){
      window.open(process.env.VUE_APP_DOMAIN + "/stock/spustock");
    }
  }
}
</script>

<style lang="scss" scoped>
#centerRight2 {
 // $box-height: 410px;
 // $box-width: 500px;
  //padding: 16px;
  // height: $box-height;
  width: 100%;
  height: 100%!important;
  padding-bottom: 0px;
  .bg-color-black{
    border-radius: 0!important;
    // padding: 5px;
    height: 100%!important;
    // width: $box-width;
    border-radius: 10px;
    padding: 0 20px;
  }

  .body-box {
    //border-radius: 10px;
    overflow: hidden;
    padding-bottom: 0px;

    .dv-cap-chart {
      width: 100%;
      height: 160px;
    }
  }

  .title11{
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    //height: 20%;
    padding-top:5%;
    padding-bottom:3%
  }
  .title22{
    height: 70%;
    //position: relative;
  }
  .commonTitle{
    cursor: pointer;
  }
}
</style>
