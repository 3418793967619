<template>
    <div id="centerRight2">
      <div class="bg-color-black">
        <div class="d-fle title11">
          <span class="fs-xl text_title commonTitle" @click="go()">织物报废排名</span>
        </div>
        <div class="d-flex flex-column body-box title22">
          <Chart v-if="showChart" :listData="listData" :color="color" type="report" />
        </div>
        <div class="title33">合计：56156</div>
      </div>
    </div>
</template>

<script>
import Chart from './chart.vue'
import * as api from '@/api/index'
import { sortArrayByKey } from '../../utils';
export default {
  data() {
    return {
      color:'#00a6a6',
      showChart:false,
      listData: [
        // {
        //   customerName: "xxxxxxx医院",
        //   num: "100",
        //   rate:'30',
        // },
        // {
        //   customerName: "xxxxxxx医院",
        //   num: "200",
        //   rate:'50',
        // }, {
        //   customerName: "xxxxxxx医院",
        //   num: "300",
        //   rate:'20',
        // }, 
        // {
        //   customerName: "xxxxxxx医院",
        //   num: 400,
        // },
        // {
        //   customerName: "xxxxxxx医院",
        //   num: "200",
        // }, {
        //   customerName: "xxxxxxx医院",
        //   num: "300",
        // }, {
        //   customerName: "xxxxxxx医院",
        //   num: 400,
        // },
      ],
      params: {
        pageNum: 1,
        pageSize: 10,
        startTime: '',
        endTime: '',
        customerId: ''
      },
      result: []
    }
  },
  components: {
    Chart
  },
  mounted() {
    const info = JSON.parse(localStorage.getItem('userInfo'))
    this.params.customerId = this.$route.query.customerId

    this.getBigSkuDisposeCustomer()
  },
  methods: {
    async getBigSkuDisposeCustomer(){
      const res = await api.getBigSkuDisposeCustomer({})
      console.error(res.Result)
      this.$nextTick(() => {
        this.showChart = true
        let total = res.Result.reduce((c, R) => c + R.num, 0)
        res.Result.forEach(item => {
          item.showNum = item.num
          item.percent = item.num / total * 100
        });
        this.listData = res.Result
        this.listData = sortArrayByKey(this.listData,'showNum')
        console.log(res.Result,'report')
      })
    },
    go(){
      window.open(process.env.VUE_APP_DOMAIN + "/report/rpt-order504");
      
      // window.open( "https://web.yiqiuyifang.com/report/rpt-order504");
    }
  }
}
</script>

<style lang="scss" scoped>
#centerRight2 {
 // $box-height: 410px;
 // $box-width: 500px;
  //padding: 16px;
  // height: $box-height;
  width: 100%;
  height: 100%!important;
  padding-bottom: 0px;
  .bg-color-black{
    border-radius: 0!important;
    // padding: 5px;
    height: 100%!important;
    // width: $box-width;
    border-radius: 10px;
    padding: 0 20px;
  }

  .body-box {
    //border-radius: 10px;
    overflow: hidden;
    padding-bottom: 0px;

    .dv-cap-chart {
      width: 100%;
      height: 160px;
    }
  }

  .title11{
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    //height: 20%;
    padding-top:5%;
    padding-bottom:3%
  }
  .title22{
    height: 62%;
    //position: relative;
  }
  .title33{
    padding-top: 3%;
    height:6%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  .commonTitle{
    cursor: pointer;
  }
}
</style>
