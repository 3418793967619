<template>
    <div class="chart-common">
        <vue-seamless-scroll :data="listData" :class-option="defineScroll" class="seamless-warp"
            ref="vueSeamlessScroll" @mousewheel.native="handleScroll">
            <div class="item">
                <li v-for="(item, index) in listData" :key="index" class="scrollLi" :class="type == 'loss' ? 'scrollLi2' : '' " @mouseenter="mouseenterFn($event,item)" @mouseleave="mouseleaveFn($event)">
                    <span class="title" v-text="item.customerName"></span>
                    <span class="num">
                        <span :style="{width: minWidth + ((item.percentNew && item.showNum != 0) ? item.percentNew :item.percent) + '%' , backgroundColor:color }">
                            {{ item.showNum }}
                        </span>  
                    </span>
                    <span class="percent" v-if="type == 'loss' ">{{ item.percent }}%</span>
                </li>
            </div>
        </vue-seamless-scroll>
        <div v-show="tooltipVisible" id="scrollTooltip" class="scrollTooltip" :style="{ top: tooltipPosition.top + 'px', left: tooltipPosition.left + 'px' }">
            <template v-if="currentCus != tooltipInfo.customerName">
                <img :src="loadingImg" class="loadingImg">
            </template>
            <template v-else>
                <div>{{ tooltipInfo.customerName }}</div>
                <div v-for="(item,index) in tooltipInfo.list" :key="index" :class="'color' + index ">
                    <span class="icon"></span>
                    {{ item.categoryName ? item.categoryName : item.name }}：{{ item.num }}
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import * as api from '@/api/index'
import loading from '@/assets/images/loading.gif';

export default {
    data() {
        return {
            loadingImg:loading,
            minWidth:34,
            options: {},
            classOption: {
                singleHeight: 40,
                waitTime: 5000
            },

            tooltipVisible: false, // 是否显示弹框
            tooltipPosition: { top: -99999999, left: -99999999 }, // 弹框位置
            tooltipInfo :{  // 弹框信息
                customerName:'',
                list:[]
            },
            hoverInterval: null,       // 弹框定时器，防止快速请求接口

            currentCus:'',
            x:0,
            y:400,  // 
            chartType: 'stock',
            categoryList:[]
        };
    },
    async mounted(){
        const res = await api.getBigSkuCategory()
        this.categoryList = res.Result.map(item => {
            item.num = 0
            return item
        })
    },
    props: {
        cdata: {
            type: Object,
            default: () => ({})
        },
        listData: {
            type: Array,
            default: () => ([])
        },
        color: {
            type: String,
            default: () => ('#ff3399')
        },
        type: {
            type: String,
            default: () => ('')
        },
    },
    computed: {
        defineScroll() {
        return {
                step: 0.5, // 数值越大速度滚动越快
                limitMoveNum: 2, // 开始无缝滚动的数据量 this.dataList.length
                hoverStop: true, // 是否开启鼠标悬停stop
                direction: 1, // 0向下 1向上 2向左 3向右
                openWatch: true, // 开启数据实时监控刷新dom
                singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
                singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
                waitTime: 5000, // 单步运动停止的时间(默认值1000ms)
            };
        },
    },
    watch: {
        cdata: {
            handler(newData) {
                // let _this = this
                // if(this.$screenWidth >= 1520){
                //     _this.options.grid = {
                //         top: "12%",
                //         left: "12%",
                //         right: "10%",
                //         bottom: "40%"
                //     }
                //     _this.options.tooltip.textStyle.fontSize = 14
                //     _this.options.xAxis.nameTextStyle.fontSize = 14
                //     _this.options.xAxis.axisLabel.textStyle.fontSize = 14
                //     _this.options.yAxis.nameTextStyle.fontSize = 14
                //     _this.options.yAxis.axisLabel.textStyle.fontSize = 14
                // }else if( this.$screenWidth >=1400 && this.$screenWidth < 1520){
                //     _this.options.grid = {
                //         top: "12%",
                //         left: "12%",
                //         right: "10%",
                //         bottom: "49%"
                //     }
                // }
            },
            immediate: true,
            deep: true
        },
        type:{
            handler(newData) {
                this.chartType = newData

                if(newData == 'stock'){
                    this.y = window.innerWidth * 0.2
                }else if(newData == 'report'){
                    this.y = window.innerWidth * 0.4
                }else if(newData == 'loss'){
                    this.y = window.innerWidth * 0.06
                    this.x = window.innerHeight * 1.75
                }else if(newData == 'lost'){
                    this.y = window.innerWidth * 0.42
                    this.x = window.innerHeight * 1.75
                }
            },
            immediate: true,
            deep: true
            
        }
    },
    methods: {
        // 鼠标移入
        async mouseenterFn ($event,item=null){

            let currentCus = $event.toElement.innerText.replace(/\d+/g, '');
            currentCus = currentCus.replace(/[^a-zA-Z0-9\u4e00-\u9fa5（）]/g, "");
            this.currentCus = currentCus
            // console.log($event.type,  currentCus)
            
            this.tooltipVisible = true; // Show tooltip
            this.tooltipPosition = { top: $event.clientY - this.y, left: $event.clientX - this.x }

            let hoverStartTime =  Date.now(); // 记录悬停开始的时间
            let hoverDuration = 0;
            let that = this
            
            // 设置定时器持续检查悬停的时间
            this.hoverInterval = setInterval(async function () {
                hoverDuration = Date.now() - hoverStartTime; // 计算悬停时间
                console.log("悬停了" + hoverDuration,  that.currentCus, that.tooltipInfo.customerName);
                if(hoverDuration > 500 && that.currentCus != that.tooltipInfo.customerName ){
                    let res;
                    if(that.chartType == 'stock'){
                        res = await api.getBigSkuCategory({ customerId: item.customerId ? item.customerId : item.id })
                    }else if(that.chartType == 'report'){
                        res = await api.getBigSkuDisposeCategory({ customerId: item.customerId ? item.customerId : item.id })
                    }else if(that.chartType == 'loss'){
                        res = await api.getSkuPreLossCategory({ customerId: item.customerId ? item.customerId : item.id })
                    }else if(that.chartType == 'lost'){
                        res = await api.getSkuLossCategory({ customerId: item.customerId ? item.customerId : item.id })
                    }
                    const Result= that.matchFn(that.categoryList,res.Result)
                    that.tooltipInfo = {
                        customerName:'',
                        list:[]
                    }
                    that.tooltipInfo.customerName = item.customerName
                    that.tooltipInfo.list = JSON.parse(JSON.stringify(Result))
                    console.error(Result)
                }
            }, 1000); // 每1000毫秒检查一次
        },
        // 鼠标移出
        async mouseleaveFn ($event){
            console.log($event.type)
            this.hoverInterval && clearInterval(this.hoverInterval);
            this.hoverInterval = null
            this.tooltipVisible = false;
        },

        matchFn(arr1, arr2) {
            // 遍历 arr1
            arr1.forEach(item1 => {
                item1.num = 0;
                // 在 arr2 中找到相同 categoryId 的项
                const match = arr2.find(item2 => item2.categoryId === item1.categoryId);
                
                // 如果找到了匹配项，更新 arr1 中的 num
                if (match) {
                    item1.num = match.num;
                }
            });

            // 返回更新后的 arr1
            return arr1;
        }
    }
}
</script>
<style lang="scss" >
.chart-common {
    // $box-height: 410px;
    // $box-width: 500px;
     //padding: 16px;
     // height: $box-height;
     width: 100%;
     height: 100%!important;
     padding-bottom: 0px;
     .seamless-warp{
        height: 60%;
        position: relative;
        top: 20%;
        //margin-top: 20%;
     }
     .bg-color-black{
       border-radius: 0!important;
     }
     .scrollLi{
       margin-bottom:10px;
       .title{
         width: 45%;display:inline-block;font-size: 15px;
         overflow:hidden; //超出的文本隐藏
         text-overflow:ellipsis; //溢出用省略号显示
         white-space:nowrap; //溢出不换行
       }
       .num{
         width: 55%;display:inline-block;font-size: 15px;
         cursor:pointer;
         span{
           background:#ff3399;padding-left:10%;display:inline-block;
           min-width: 34%;
           max-width: 90%;
           margin-left: 2%;
         }
       }
     }
     .scrollLi2{
        .num{
          width: 40%;
          span{
            //min-width: 20%;
            //max-width: 80%;
          }
        }
        .percent{
            width: 15%;
        }
      }
     .scrollLi:hover,.scrollLi:active,.scrollLi:visited{
       white-space: nowrap;
       background-color: rgba(35, 38, 48);  
       color: rgb(255, 255, 255);
     }
     .scrollLi {
       //position: relative;
       margin-bottom: 10px;
     }
     
     .scrollTooltip {
       position: absolute;
       right: 0;
       top: 20%;
       //border: 1px solid #fff;
       padding: 10px 15px;
       z-index: 10;
       width:auto;
       min-width: 35%;
       max-width: 40%;
       overflow: auto;
       background-color: rgba(0, 0, 0, 0.7);
       //background: red;
       color: rgb(255, 255, 255);
       border-radius: 5px;
       font-size: 14px;
       display: flex;
       flex-direction: column;
       //align-items: center;
       justify-content: center;
       flex-shrink: 0;
   
       div:first-child{
         margin-bottom: 5px;
         text-align: left;
       }
       div{
         flex-shrink: 0;
         margin-bottom: 3px;
         display: flex;
         flex-direction: row;
         align-items: center;
       }
       .loadingImg{
        width: 30px;
        height: 30px;
       }
       .icon{
            display: inline-block;
            width: 7px;
            height: 7px;
            border-radius: 7px;
            margin-right: 7px;
       }
     }
     
     .scrollTooltip.visible {
       display: flex;
     }
   
     ::v-deep .dv-scroll-ranking-board .ranking-info .ranking-value {
       // display: none;
     }
   
     ::v-deep  .ranking-column {
        border: none;
       margin-bottom: -5px;
       margin-top: 0px;
       .inside-column {
         background-color: #e77d35;
       }
       .row-item{
         height: 24px!important;
       }
     }
   
     .bg-color-black {
       // padding: 5px;
       height: 100%!important;
       // width: $box-width;
       border-radius: 10px;
       padding: 0 20px;
     }
   
     .body-box {
       //border-radius: 10px;
       overflow: hidden;
       padding-bottom: 0px;
   
       .dv-cap-chart {
         width: 100%;
         height: 160px;
       }
     }
     .jc-end{
       margin-bottom: 10px
     }
     .d-title{
       justify-content: space-between;
       //margin-top: 10px;
       height: 15%;
     }
     .table-list{
       height: 100%;
       .top_scrap{
         width: 98%;
       }
     }
     .bfsl{
       width: 70%;
       text-align: right;
     }
     .fz17{
       font-size: 14px;
     }
   
     .title11{
       display: flex;
       flex-direction: row;
       align-items: flex-end;
       //height: 20%;
       padding-top:5%;
       padding-bottom:3%
     }
     .title22{
       height: 70%;
       //position: relative;
     }
     .item-li{
       .numWashing{
         display: inline-block;
         height: 22px;
       }
     }
   }


   
   .w50{
     width: 50%;
     text-align: center;
   }
   .w25{
     width: 25%;
     text-align: center;white-space:nowrap;
   }







    .color0 span{
        background-color: #37a2da!important;
    }
    .color1 span{
        background-color: #6871d0!important;
    }
    .color2 span{
        background-color: #4d9f88!important;
    }
    .color3 span{
        background-color: #ffdb5c!important;
    }
    .color4 span{
        background-color: #8378ea!important;
    }
    .color5 span{
        background-color: #e7bcf3!important;
    }
    .color6 span{
        background-color: #006de6!important;
    }
    .color7 span{
        background-color: #00d2f5!important;
    }
</style>